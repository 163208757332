    #feed {
      display: flex;
      width: 100%;
      max-width: rem(500);
      margin: 0 auto;
      flex-flow: row wrap;
      position: relative;
      z-index: 1;
  
      > div {
        width: 33%;
        padding: 1%;
  
        @media screen and (max-width: 800px) {
          flex-basis: 50%;
          &:nth-last-child(3), &:nth-last-child(2){
            display: none;
          }
        }
  
        a {
          background-repeat: no-repeat;
          background-size: cover;
          display: block;
          overflow: hidden;
          padding-bottom: 100%;
          position: relative;
          width: 100%;

          &.button{
            padding-bottom: 0;
          }
  
          span {
            align-items: center;
            background-color: rgba(0, 0, 0, .7);
            color: $white;
            display: flex;
            font-weight: 300;
            justify-content: center;
            line-height: 1.3;
            padding: rem(15);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            transform: translateX(-100%);
            transition: transform .3s;
          }
  
          &:active,
          &:focus,
          &:hover {
            span {
              transform: translateX(0);
            }
          }
        }
      }
    }

  