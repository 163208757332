@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "includes/feed";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel"; 

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    text-align: center;
    cursor: url('../../dist/img/CustomCursor.png'), auto; 
}

.container{
    max-width: rem(980);
}

a{
    color: inherit;
    @include hover{
        color: $primary-color;
    }
}
#header{
    position: relative;
    width: 100%;

    h1{
        padding: 0 rem(10);
        img{
            display: block;
            margin: 0 auto;
        }
    }
    .video-container{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .nav{
        font-family: $bold-font;
        font-weight: bold;
        padding: rem(50) 0 rem(10);
        font-size: rem(25);
        letter-spacing: .02em;
        text-transform: uppercase;
        @media screen and (max-width: 700px){
            font-size: rem(16);
            padding-top: rem(30);
        }
        @media screen and (max-width: 359px){
            font-size: rem(14);
        }
        li{
            display: inline-block;
            position: relative;
            &+li{
                margin-left: rem(72);
                @media screen and (max-width: 700px){
                    margin-left: rem(20)
                }
                @media screen and (max-width: 359px){
                    margin-left: rem(12)
                }
                
                a{
                    display: block;
                    &::before{
                        content: '';
                        position: absolute;
                        height: 3px;
                        background-color: $white;
                        width: rem(54);
                        top: calc(50% - 2px);
                        left: rem(-66);
                        @media screen and (max-width: 700px){
                            width: rem(18);
                            left: rem(-22);
                        }

                        @media screen and (max-width: 359px){
                            width: rem(10);
                            left: rem(-13);
                        }
        
                    }
                }
            }
            a{
                &::after{
                    content: '';
                    position: absolute;
                    height: 3px;
                    background-color: $white;
                    top: calc(50% - 2px);
                    right: rem(-15);
                    left: rem(-12);
                    opacity: 0;
                    transition: opacity .3s;
                }
                @include hover{
                    color: $white;
                    &::after{
                        opacity: 1;
                    }
                }
            }
        }
    }

    .btn-wrap{
        padding-bottom: rem(50);
        a{
            display: inline-block;
            margin: rem(10);
        }
    }

    .container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        min-height: 100vh;
    }
}

#main{
    padding-top: 1px;
    background-image: url('../../dist/img/texture.png');
    background-size: cover;
    background-position: bottom center;
}

h2{
    font-size: rem(40);
    letter-spacing: .6em;
    margin: rem(40) 0;
    text-transform: uppercase;
    text-align: center;
    font-family: $bold-font;
}


.more{
    display: inline-block;
    margin: rem(48) 0;
}

#music{
    .owl-carousel{
        margin: 0 rem(-10);
        width: calc(100% + 1.25rem);
        .text{
            width: auto !important;
            display: inline-block;
        }
    }
}

#video{

    h2, .more{
        padding-left: rem(10);
        padding-right: rem(10);
    }
    .content{
        display: flex;
        @media screen and (max-width: 600px){
            flex-direction: column;
        }
        .item{
            flex:1;
            position: relative;
            >div:first-child{
                position: absolute;
                z-index: 10;
                left:50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }


            video{
                display: block;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
}

#follow{
    display: flex;
    margin-bottom: rem(50);
    flex-direction: row-reverse;
    @media screen and (max-width: 700px){
        flex-direction: column;
        max-width: rem(315);
        margin: 0 auto rem(50);

    }
    >div{
        flex: 1;
    }

    .newsletter{
        margin-top: rem(40);
        @media screen and (max-width: 700px){
            margin-bottom: rem(40);
        }
        input{
            display: inline-block;
            vertical-align: middle;
            border: 1px solid $white;
            font-size: rem(14);
            padding: rem(5) rem(5) rem(6);
            color: $white;
            width: rem(250);
            @media screen and (max-width: 700px){
                width: rem(200);
                padding: rem(6);
            }
            text-transform: uppercase;

            &::placeholder{
                color: $white;
            }
        }
        .newsletter-submit{
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            img{
                display: block;
            }
        }
    }

    .instagram{
        @media screen and (max-width: 700px){
            margin-bottom: rem(40);
        }
    }
}



#footer{
    .copyright{
        font-size: rem(8);
        text-transform: uppercase;
        letter-spacing: .01em;
    }
    .social{
        li{
            display: inline-block;
            vertical-align: middle;
            &+li{
                margin-left: 1em;
            }
        }
    }
}

.text{
    filter: contrast(0) brightness(500%);
    transition: filter .3s;
    @include hover{
        filter: contrast(100%) brightness(100%);
    }
}

@media screen and (min-width: 700px){
    .mobile700{
        display: none !important;
    }
}
@media screen and (max-width: 701px){
    .desktop700{
        display: none !important;
    }
}

@media screen and (min-width: 1000px){
    .mobile{
        display: none !important;
    }
}
@media screen and (max-width: 1001px){
    .desktop{
        display: none !important;
    }
}