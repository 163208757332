$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$primary-color: #e2223d;
$gray: #656565;


// Primary Font
$primary-font: "Helvetica Neue LT W05_65 Medium", sans-serif;
$bold-font: "Helvetica Neue LT W05_75 Bold", sans-serif;
$condensed-font: "Helvetica Neue LT W05_37 Th Cn", sans-serif;

