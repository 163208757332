*,
*:before,
*:after {
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

*:active,
*:focus {
  border-color: inherit;
  box-shadow: none;
    -webkit-box-shadow: none;
  outline: none;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

a,
a:link,
a:visited,
button,
[role="button"],
[type="button"],
[type="submit"] {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
}

[type="button"],
[type="color"],
[type="date"],
[type="email"],
[type="file"],
[type="image"],
[type="month"],
[type="number"],
[type="password"],
[type="range"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
[role="button"],
button,
select,
textarea {
  -webkit-appearance: none;
  background: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

img {
  height: auto;
  max-width: 100%;
}

iframe, embed, object, video {
  border: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
  line-height: 1;
}

ul[class] {
  list-style: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
}

.media-responsive {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0 0 56.25%;
  position: relative;
  
  > img {
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  iframe,
  embed,
  object,
  video {
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.sr {
  border: 0;
  height: 1px;
  left: -99999px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.container {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
}